import { graphql } from 'gatsby';
import React from 'react';

import { BaseSanityPage, PortableTextBlocks, PracticeArea } from 'types/schema';
import Box from 'components/Box';
import MainLayout from 'components/layout/MainLayout';
import PagePictureTitle from 'components/layout/PagePictureTitle';
import PortableText from 'components/PortableText';
import PracticeAreasSection from 'components/layout/PracticeAreasSection';
import MultiSectionBackgroundWrap from 'components/layout/MultiSectionBackgroundWrap';
import variants from 'components/theme/variants';

export const pageQuery = graphql`
  query BusinessLawPageQuery {
    sanityBusinessLawPage(id: { eq: "-adf7642b-3ca3-5a14-92ac-7f6381f6850a" }) {
      id
      meta {
        title
        description
      }
      pageTitle
      _rawHeaderContent
      practiceAreas {
        title
        _rawBody
        _key
      }
    }
  }
`;

type Props = {
  data: {
    sanityBusinessLawPage: BaseSanityPage & {
      _rawHeaderContent: PortableTextBlocks;
      practiceAreas: PracticeArea[];
    };
  };
};

const BusinessLawPage: React.FC<Props> = ({
  data: {
    sanityBusinessLawPage: {
      meta: { title, description },
      pageTitle,
      _rawHeaderContent,
      practiceAreas,
    },
  },
}) => {
  return (
    <MainLayout seoProps={{ title, description, pagePath: '/business-law/' }}>
      <PagePictureTitle
        title={pageTitle}
        pictureUrl="https://images.unsplash.com/photo-1477146901578-40f6955116e1"
        outerSx={{ backgroundPosition: 'center top' }}
        pb={[7, 9]}
      >
        <Box
          mt={10}
          width="100%"
          maxWidth={800}
          mx="auto"
          bg="grey90090"
          color="white"
          py={7}
          px={8}
          sx={{
            '& div': {
              '& > * + *': { mt: 3 },
              '& > * + h2': { mt: 6 },
            },
            h2: { ...variants.header2Serif },
          }}
        >
          <PortableText blocks={_rawHeaderContent} />
        </Box>
      </PagePictureTitle>
      <MultiSectionBackgroundWrap>
        <PracticeAreasSection practiceAreas={practiceAreas} />
      </MultiSectionBackgroundWrap>
    </MainLayout>
  );
};

export default BusinessLawPage;
